import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import isURL from "validator/lib/isURL";

import PaymentMethod from "../PaymentMethod/PaymentMethod";
import SelectedPackage from "../SelectedPackage/SelectedPackage";
import VoucherView from "../VoucherView/VoucherView";
import PackageTitle from "./PackageTitle";

import classes from "./PaymentMethodsView.module.css";
import classes2 from "./PackagesView.module.css";
import settings from "../../../configs/config_settings.json";
import Loader from "../../Common/Loader/Loader";

import { ROUTES } from "../../../constants/route";
import { getPaymentUrl } from "../../../scripts/dataHandlers";
import { useMyContext } from "../../../contexts/StateHolder";

const PaymentMethodsView = (props) => {
	const { paymentMethods, buyPackageSuccess } = props;
	const { organizationId } = settings.organization;

	const { user, selectedPackage, userLoggedIn } = useMyContext();

	const { t } = useTranslation();

	const { itemId } = useParams();

	const [cookies] = useCookies("");
	const history = useHistory();

	const [voucherView, setVoucherView] = useState(null);
	const [paymentMethodError, setPaymentMethodError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const buyPackage = async (methodId, packageId) => {
		setIsLoading(true);
		let handlerUrl = window.location.protocol + "//" + window.location.hostname;
		handlerUrl += window.location.hostname.includes("localhost") ? ":" + window.location.port : "";
		handlerUrl += props.settings.returnUrlPath;

		let TVODASSETID =
			selectedPackage?.category?.pricing === 1 &&
			selectedPackage?.category?.type === 0 &&
			selectedPackage?.category?.contentType === 0
				? itemId
				: undefined;
		try {
			const paymentUrl = await getPaymentUrl({
				userToken: cookies?.user?.userToken,
				organizationId,
				packageId,
				paymentMethodId: methodId,
				userEmail: user.eMail,
				handlerUrl,
				assetId: TVODASSETID,
			});

			if (
				paymentUrl.status === "ok" &&
				typeof paymentUrl.redirectUrl !== "undefined" &&
				isURL(paymentUrl.redirectUrl)
			) {
				buyPackageSuccess(paymentUrl.redirectUrl);
			} else {
				let errorCode = 19;
				if (paymentUrl.status === "error" && typeof paymentUrl.errorCode !== "undefined") {
					errorCode = paymentUrl.message;
				}
				setIsLoading(false);

				setPaymentMethodError(errorCode);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const buyFunction = (methodId, methodKey) => {
		//if user is not logged in.
		//This should never happen cause paymentmethods are disabled when user is not logged in
		if (!userLoggedIn) {
			console.log("Can't buy because needs to log in");
			return;
		}
		//if no selected package then no buy either.
		//This really shouldn't happen either. You shouldn't be able to come here without selecting package
		if (Object.keys(selectedPackage).length === 0) {
			console.log("Can't buy without package");
			return;
		}
		if (methodKey === "voucher") {
			setVoucherView("active");
		} else {
			buyPackage(methodId, selectedPackage.id);
		}
	};

	const hideVoucher = () => {
		setVoucherView(null);
	};

	const moveToReceipt = () => {
		setVoucherView(null);
		history.push(`${ROUTES.PACKAGES}/receipt/${selectedPackage.id}?assetId=${itemId}`);
	};

	return (
		<div
			className={"asset-main-container package-container"}
			style={{
				flexDirection: "column",
			}}
		>
			{isLoading ? (
				<Loader />
			) : (
				selectedPackage && (
					<>
						<PackageTitle title={t("packages.paymentMethod")} active />

						<div
							style={{
								margin: "30px auto",
							}}
						>
							{!userLoggedIn && (
								<div className={classes.paymentUserNotLoggedIn}>
									{t("packages.PackageUserNotLoggedIn")}
								</div>
							)}

							<div>{t("packages.PackagePaymentHelp")}</div>
						</div>

						{paymentMethodError && (
							<div className={classes.paymentMethodError}>{paymentMethodError}</div>
						)}
						<div className={classes2.packages}>
							{Object.values(paymentMethods).map((value) => (
								<PaymentMethod
									key={value.id}
									paymentMethod={value}
									buyFunction={buyFunction}
									userLoggedIn={userLoggedIn}
								/>
							))}
						</div>
						{voucherView && (
							<VoucherView
								hideVoucher={hideVoucher}
								moveToReceipt={moveToReceipt}
								selectedPackage={selectedPackage}
								itemId={itemId}
							/>
						)}
					</>
				)
			)}
			<SelectedPackage />
		</div>
	);
};

export default PaymentMethodsView;
