import Axios from "axios";
import { checkArguments, createAccountToken, createChangePasswordToken } from "./utils";
import { countries } from "./countries";
import { AppConfig } from "../configs/config";

// Get items that are listed in groupItemId -string
const getSimilar = async (organizationId, groupItemId, token, lang, assetProperty, user) => {
	if (checkArguments(organizationId, groupItemId, token, lang, assetProperty)) {
		try {
			const itemResponse = await Axios.get(
				`${
					AppConfig.BASE_URL
				}/publishing/getAssets?version=06&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&assetProperty=${
					assetProperty || ""
				}&orderBy=publishStart&limit=10&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
			);
			console.log("getSimilar: ", itemResponse);
			return itemResponse.data.assets;
			// return removePunctuationFromResponse(itemResponse.data.assets);
		} catch (err) {
			console.log(err);
		}
	}
};

export const getRMPLicense = async (host) => {
	try {
		let url = `${AppConfig.BASE_URL_CDN_SUITE_CACHE}/suite/radiantplayerlicenses.json`;

		const res = await Axios.get(url);

		// console.log("res", res);
		return res?.data?.licenses;
	} catch (error) {
		console.log(error);
	}
};

// Authenticate user
const authenticateUser = async (organizationId, email, password, role) => {
	try {
		let url = `${AppConfig.BASE_URL_MY}/login?action=authenticate`;

		const authResponse = await Axios.get(url, {
			params: {
				organizationId,
				eMail: email,
				password: password,
				...(role && role ? { role: "Super Administrator" } : { role: "ismsubscriber" }),
			},
		});
		// console.log("Authentication: ", authResponse);
		return authResponse;
	} catch (err) {
		console.log(err);
	}
};
export const selfDeleteUser = async (userId, userToken) => {
	try {
		let url = `${AppConfig.BASE_URL_MY}/user?action=removeSelf`;

		let params = {
			organizationId: AppConfig.organization.organizationId,
			userId,
		};
		let headers = {
			"Content-Type": "application/json",
			userToken,
		};

		// Make the DELETE request with axios or your preferred HTTP client
		const response = await Axios.get(url, { headers, params });

		// Handle the response as needed
		console.log(response.data);

		return response?.data;
	} catch (error) {
		console.log(error);
	}
};

const registerUser = async (organizationId, inputs, role) => {
	console.log("organizationId", organizationId);
	console.log("inputs", inputs);

	let extraUserSettings = [];

	if (inputs?.ORGANIZATIONNAME?.value) {
		extraUserSettings.push({
			type: "text",
			value: inputs?.ORGANIZATIONNAME?.value,
			name: "organizationName",
		});
	}

	if (inputs?.YTUNNUS?.value) {
		extraUserSettings.push({
			type: "text",
			value: inputs?.YTUNNUS?.value,
			name: "yTunnus",
		});
	}

	try {
		const registerResponse = await Axios({
			method: "post",
			url: `${AppConfig.BASE_URL_MY}/register`,
			params: {
				action: "addSubscriber",
				organizationId,
				emailAddress: inputs?.EMAIL?.value,
				userPassword: inputs?.PASSWORD?.value,
				...(inputs?.PHONE?.value ? { phoneNumber: inputs?.PHONE?.value } : "-"),
				...(inputs?.CITY?.value ? { cityName: inputs?.CITY?.value } : "-"),
				...(inputs?.DATEOFBIRTH?.value ? { dateOfBirth: inputs?.DATEOFBIRTH?.value } : "-"),
				...(inputs?.COUNTRY?.value
					? {
							countryId: countries.find((country) => country.name === inputs?.COUNTRY?.value).id,
					  }
					: "-"),
				...(inputs?.FIRSTNAME?.value ? { firstName: inputs?.FIRSTNAME?.value } : "-"),
				...(inputs?.LASTNAME?.value ? { lastName: inputs?.LASTNAME?.value } : "-"),
				...(inputs?.ADDRESS?.value ? { address1: inputs?.ADDRESS?.value } : "-"),
				...(inputs?.POSTALCODE?.value ? { postalCode: inputs?.POSTALCODE?.value } : "-"),
				...(extraUserSettings.length > 0
					? { extraUserSettings: JSON.stringify(extraUserSettings) }
					: {}),
				...(role ? { role: role } : { role: "ismsubscriber" }),
				emailSettingsFromParentOrg: false, // USE THIS WHEN THIS ORGANIZATION IS CREATED UNDER SOME PARENT ACCOUNT ->CAUSE MAIL IS SENT FROM THE PARENT ACCOUNT->IN CASE OF KOTIKINO IT IS CREATED UNDER
			},
		});

		console.log("Register: ", registerResponse);
		return registerResponse;
	} catch (err) {
		console.log(err);
	}
};
export const resetPasswordChange = async (newPassword, confirmPassword, token) => {
	let url = `${AppConfig.BASE_URL}/user?action=setNewPassword`;
	const res = await Axios.get(url, {
		params: {
			newPassword,
			confirmPassword,
			token,
		},
	});

	console.log("reset password response: ", res);
	return res;
};

export const validatePasswordResetToken = async (token) => {
	let url = `${AppConfig.BASE_URL}/user?action=validatePasswordToken`;
	const res = await Axios.get(url, {
		params: {
			token,
		},
	});

	console.log("validatePasswordResetToken response: ", res);
	return res;
};
export const requestResetPassword = async (emailAddress, organizationId, role, token) => {
	let url = `${AppConfig.BASE_URL}/user?action=resetPassword`;
	const requestResetPasswordResponse = await Axios.get(url, {
		params: {
			organizationId,
			emailAddress,
			role,
			token,
		},
	});

	console.log("requestResetPassword response: ", requestResetPasswordResponse);
	return requestResetPasswordResponse;
};
const changePassword = async (
	organizationId,
	key,
	userId,
	newPassword,
	confirmPassword,
	oldPassword,
	userToken
) => {
	const token = createChangePasswordToken(
		organizationId,
		key,
		userId,
		newPassword,
		confirmPassword
	);

	try {
		// TODO: API CHANGES TO PASSWORD CHANGE REQUEST
		const changePasswordResponse = await Axios.get(
			`${AppConfig.BASE_URL}/user?action=changePassword&organizationId=${organizationId}&userId=${userId}&newPassword=${newPassword}&confirmPassword=${confirmPassword}&token=${token}&oldPassword=${oldPassword}&userToken=${userToken}`
		);

		console.log("ChangePassword: ", changePasswordResponse);
		return changePasswordResponse;
	} catch (err) {
		console.log(err);
		return err;
	}
};

// Update user
const updateUser = async (
	userId,
	userToken,
	organizationId,
	key,
	firstName,
	lastName,
	phoneNumber,
	countryId,
	regionId,
	cityName,
	postalCode,
	emailAddress,

	address
) => {
	const token = createAccountToken(
		userId,
		organizationId,
		key,
		firstName,
		lastName,
		phoneNumber,
		countryId,
		regionId,
		cityName,
		postalCode
	);

	let headers = {
		"Content-Type": "application/json",
		userToken,
	};

	console.log("address", address);

	const getFormattedDate = () => {
		// Get the current date
		const currentDate = new Date();

		// Extract year, month, and day
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const day = String(currentDate.getDate()).padStart(2, "0");

		// Construct the formatted date string
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	let dateOfBirth = getFormattedDate();

	try {
		const updateResponse = await Axios.get(`${AppConfig.BASE_URL_MY}/user`, {
			params: {
				action: "updateUser",
				organizationId,
				firstName,
				lastName,
				emailAddress,
				countryId,
				regionId,
				postalCode,
				cityName,
				phoneNumber,
				token,
				...(dateOfBirth ? { dateOfBirth: dateOfBirth } : {}),
				...(address ? { address1: address } : {}),
			},
			headers,
		});
		console.log("updateUser: ", updateResponse);
		return updateResponse;
	} catch (err) {
		console.log(err);
	}
};
// Get user data
const getUser = async (userToken, organizationId) => {
	try {
		const getUserResponse = await Axios.get(
			`${AppConfig.BASE_URL_MY}/user?action=getUserProfile&organizationId=${organizationId}&userToken=${userToken}`
		);
		console.log("getUser: ", getUserResponse);
		return getUserResponse;
	} catch (err) {
		console.log(err);
	}
};

// Search assets
const searchAssets = async (keyword, user, organizationId, language) => {
	try {
		const getSearchResponse = await Axios.get(
			`${AppConfig.BASE_URL}/search/assets?action=searchAssets`,
			{
				params: {
					organizationId,
					keyword: keyword,
					...(user?.userToken ? { userToken: user?.userToken } : {}),
					...(language ? { language: language } : {}),
					...(language ? { languageId: language } : {}),
					...(user?.userId ? { userId: user?.userId } : {}),
				},
			}
		);

		console.log("searchAsset: ", getSearchResponse);
		return getSearchResponse;
	} catch (err) {
		console.log(err);
	}
};

const getPlayerConfig = async (organizationId, channelID) => {
	try {
		const res = await Axios.get(
			`${AppConfig.BASE_URL}/applications?action=getAppConfig&appId=133485121`
		);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

// Get organizations packages based on given object (if no object given it will fetch all packages)
const getPackages = async (organizationId, lang, objectId, userToken) => {
	if (checkArguments(organizationId, lang)) {
		try {
			const params = {
				action: "getPackages",
				organizationId,
				languageId: lang,
			};
			if (objectId > 0) {
				params.objectId = objectId;
			}
			if (userToken) {
				params.userToken = userToken;
			}
			const packagesResponse = await Axios.get(`${AppConfig.BASE_URL}/packages`, {
				params,
			});
			console.log("Org packages: ", packagesResponse);
			return packagesResponse.data;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get all shopping categories for organization
export const getShoppingCategories = async (organizationId, lang) => {
	if (checkArguments(organizationId, lang)) {
		try {
			const params = {
				action: "getShoppingCategories",
				organizationId,
			};
			const shoppingCategoriesResponse = await Axios.get(`${AppConfig.BASE_URL}/packages`, {
				params,
			});
			console.log("Shopping Cats: ", shoppingCategoriesResponse);
			return shoppingCategoriesResponse.data;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get organizations payment methods
const getPaymentMethods = async (organizationId) => {
	try {
		const params = {
			action: "getPaymentMethods",
			organizationId,
		};
		const paymentMethodsResponse = await Axios.get(`${AppConfig.BASE_URL}/payment/methods`, {
			params,
		});
		console.log("Payment methods: ", paymentMethodsResponse);
		return paymentMethodsResponse.data;
	} catch (err) {
		console.log(err);
	}
};

//get payment url
const getPaymentUrl = async ({
	userToken,
	organizationId,
	packageId,
	paymentMethodId,
	userEmail,
	handlerUrl,
	assetId,
}) => {
	try {
		const params = {
			action: "initPurchase",
			userToken,
			organizationId,
			packageId,
			paymentMethodId,
			userEmail,
			handlerUrl,
			...(assetId ? { assetId: assetId } : {}),
		};
		const paymentUrlResponse = await Axios.get(`${AppConfig.BASE_URL_MY}/purchase`, {
			params,
		});
		console.log("Url", paymentUrlResponse);
		return paymentUrlResponse.data;
	} catch (err) {
		console.log(err);
	}
};

// return from bambora payment
const purchasePackage = async (userToken, oldParams) => {
	try {
		let params = oldParams;
		params.userToken = userToken;
		params.action = "return";

		const purchasePackageResponse = await Axios.get(`${AppConfig.BASE_URL_MY}/purchase/return`, {
			params,
		});

		console.log("PP: ", purchasePackageResponse);
		return purchasePackageResponse.data;
	} catch (err) {
		console.log(err);
	}
};

// Try to make Voucher purchase
const voucherPurchase = async ({
	userToken,
	languageId,
	organizationId,
	productId,
	voucherCode,
	quantity,
	assetId,
}) => {
	try {
		const params = {
			userToken,
			action: "activateVoucher",
			voucherCode,
			languageId,
			productId,
			usedDate: new Date().getTime(),
			organizationId,
			quantity,
			...(assetId ? { assetId: assetId } : {}),
		};
		const voucherPurchaseResponse = await Axios.get(`${AppConfig.BASE_URL}/purchase`, {
			params,
		});
		return voucherPurchaseResponse;
	} catch (err) {
		console.log(err);
	}
};

export const cancelSVODSubscription = async (organizationId, shoppingBuyerProductId, userToken) => {
	try {
		const url = `${AppConfig.BASE_URL_MY}/purchase`;
		const params = {
			action: "cancelSubscription",
			organizationId: organizationId,
			shoppingBuyerProductId,
			_: Date.now(),
			token: userToken, // HERE userToken is accepted as token
		};

		return await Axios.get(url, { params });
	} catch (err) {
		console.log(err);
	}
};

export {
	getPackages,
	getPaymentMethods,
	getPaymentUrl,
	purchasePackage,
	voucherPurchase,
	getSimilar,
	getUser,
	authenticateUser,
	changePassword,
	registerUser,
	searchAssets,
	updateUser,
	getPlayerConfig,
};
