import { useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useMyContext } from "../../contexts/StateHolder";

import classes from "./FAQ.module.css";
import { ROUTES } from "../../constants/route";

const FAQFi = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const { languageCode } = useMyContext();

	useEffect(() => {
		if (languageCode?.includes("en")) {
			return history.push(`/en${ROUTES.FAQ}`);
		}
	}, [languageCode, history]);

	return (
		<div className="container">
			<div className={`${classes.faqContainer} font-300`}>
				<div className={`${classes.faqTitle} font-600`}>{t("faq.title")}:</div>
				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Miten tilaan ja saanko tilausvahvistuksen?
					</div>
					<br />
					Asiakas rekisteröityy Kotikino -järjestelmään ja luo sinne henkilökohtaisen tunnuksen
					(sähköposti sekä salasana). Tilaus lähetetään maksamalla tilattupalvelu verkkokaupan
					maksajärjestelmällä. Jos asiakkaalle ei syystä tai toisesta tule tilausvahvistusta, tilaus
					ei todennäköisesti ole onnistunut, tai annetussa sähköpostiosoitteessa on ollut virhe.
					Kehotamme tällaisessa tilanteessa ottamaan viivytyksettä yhteyttä asiakaspalveluumme.
					<br />
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Miten voin maksaa palvelusta?</div>
					<br />
					Käytämme Kotikinon maksunvälittäjä Stripe -palvelua, jonka kautta voit maksaa
					maksukorteilla (luotto/pankki) tai MobilePay -mobiilisovelluksella. Käytettävissä ovat
					seuraavat korttivaihtoehdot: Visa, MasterCard, American Express, Diners Club, Discover ja
					JCB -kortit. Sisältöoikeuksien takia sallimme tällä hetkellä ainoastaan maksut
					suomalaisilla korteilla.
					<br />
					<br />
					Lisäksi maksutavaksi käy erikseen lunastettu arvoseteli (voucher).
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Maksu tapahtuma keskeytyy / maksu ei onnistu, mitä voin tehdä?
					</div>
					<br />
					Jos valitsit Stripe -maksumenetelmän, niin aukeaa ponnahdusikkunat. Varmista että
					ponnahdusikkunat ovat sallittu selaimessasi ja saat hyväksyttyä maksun
					pankkitunnuksillasi.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Kuinka kauan aikaisemmin on maksettava maksu, jotta se ehtii tulla vahvistetuksi ja näen
						tapahtuman?
					</div>
					<br />
					Maksujärjestelmä lähettää ilmoituksen Kotikinoon heti, kun maksu on hyväksytty eli pääset
					katsomaan sisältöjä välittömästi maksun suorituksen jälkeen.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Voinko maksaa kulttuurisetelillä?</div>
					<br />
					Emme tällä hetkellä tue maksua kulttuuriseteleillä.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Koska tilaukseni alkaa?</div>
					<br />
					Tilauksesi alkaa sillä hetkellä, kun maksusi on hyväksytty. Tilaus on voimassa valitsemasi
					paketin pituuden mukaan maksuhetkestä esimerkiksi:
					<br />
					<br />
					• 24h (kertaostot)
					<br />
					• Jatkuvasti (kuukausiveloitteinen palvelu)
					<br />
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Kuinka voin peruuttaa kestotilaukseni?
					</div>
					<br />
					Voit itse peruuttaa kestotilauksesi. Kirjaudu tunnuksella, jonka tilauksen haluatte
					lopettaa. Kirjautumisen jälkeen menemällä Profiiliin ylä kulman ikonista ja valitse 'Omat
					tilaukseni'. Siellä näkyvät kaikki teidän tilauksenne. Voimassa olevan kestotilauksen
					kohdalla on nappi, jolla tilauksen voi päättää. Kun kyseessä on kestotilaus niin voitte
					käyttää palvelua seuraavaan maksuhetkeen saakka. Se, että tuliko peruutus läpi niin sen
					näkee siitä, että se peruutusnappi poistuu näkyvistä.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Millä päätelaitteilla voin katsoa sisältöjä?
					</div>
					<br />
					Kotikino -palvelu toimii kaikissa uusimmissa selaimissa eri desktop-, mobiili- ja
					tablet-päätelaitteissa.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Toimiiko palvelu Chromecastilla?</div>
					<br />
					Kotikino:n käyttämä videosoitin tukee myös Chromecast / Googlecast -ominaisuutta joissakin
					päätelaitteissa, mutta päätelaitteissa on eroja ja emme pysty tällä hetkellä testaamaan
					niitä kaikkia ja antamaan tukea sen toiminnalle.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Rajoitetaanko katselumäärää?</div>
					<br />
					Emme rajoita kuukausitilauksien katselun määrää eli voit katsoa niin paljon eri pakettiin
					kuuluvia sisältöjä kuin haluat tilauksesi voimassaoloaikana.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Video pätkii, mitä voin tehdä?</div>
					<br />
					Videon pätkiminen voi johtua monesta syystä. Sujuva katselu vaatii vain noin 3 Mbps
					nettiyhteyden, mutta erityisesti poikkeusaikoina voi nettiyhteydet eri puolella olla niin
					suuressa käytössä, että yhteys pätkii. Mikäli saatavilla, suosittelemme silloin
					kokeilemaan toista yhteyttä joko mobiili-nettiä tai kodin Wifi-yhteyttä. Myös selaimen,
					päätelaitteen tai (kodin laajakaista yhteyttä käytettäessä nettimodeemin) käynnistäminen
					uudelleen voi auttaa.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Minulla on ongelma kirjautumisen/rekisteröitymisen kanssa, mitä voin tehdä?
					</div>
					<br />
					Mikäli sinulla on ongelmia rekisteröitymisen/ kirjautumisen kanssa, niin kokeile toista
					Internet-selainta (esim. Mozilla Firefox, Chrome tai Windows Edge).
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Mitä teen, kun en saa yksin selvitettyä asiaa?
					</div>
					<br />
					Voit ottaa yhteyttä asiakaspalveluumme <Link to={ROUTES.CONTACT}>Ota yhteyttä</Link>{" "}
					-sivun lomakkeen kautta.
					<br />
					<br />
					Palvelemme MA-PE välillä 8:00 - 16:00 ja pyrimme vastaamaan 48h sisällä saatuamme viestin
				</div>
			</div>
		</div>
	);
};

export default FAQFi;
