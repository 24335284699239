import { useTranslation } from "react-i18next";

import classes from "./InfoData.module.css";
import { convertDuration } from "../../../../scripts/utils";

const InfoData = ({ asset }) => {
	const { t } = useTranslation();

	const renderInfoData = (data) => {
		return (
			<div
				className={`font-300   ${classes.container}`}
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
					}}
				>
					<div>
						<div className={classes.title}>{t("detailsInfo.director")}</div>

						<div className={`${classes.title} thin-book font-200`}>
							{data?.director ? data?.director : "-"}
						</div>
					</div>
					<div>
						<div className={classes.title}>{t("detailsInfo.actors")}</div>

						<div className={`${classes.title} thin-book font-200`}>
							{data.actors ? data?.actors?.replace(/,/g, ", ") : "-"}
						</div>
					</div>

					<div>
						<div className={classes.title}>{t("detailsInfo.releaseYear")}</div>

						<div className={`${classes.title} thin-book font-200`}>
							{data.releaseYear ? data.releaseYear : "-"}
						</div>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
					}}
				>
					<div>
						{data.duration ? (
							<div className={classes.title}>{t("detailsInfo.duration")}</div>
						) : null}

						{data.duration ? (
							<div className={`${classes.title} thin-book font-200`}>
								{convertDuration(data.duration)}
							</div>
						) : null}
					</div>

					<div>
						<div className={classes.title}>{t("detailsInfo.languages")}</div>

						<div className={`${classes.title} thin-book font-200`}>
							{data?.spokenLanguages !== ""
								? data?.spokenLanguages?.split(",")?.join(", ")?.toUpperCase()
								: "-"}
						</div>
					</div>

					<div>
						<div className={classes.title}>{t("detailsInfo.subtitles")}</div>

						<div className={`thin-book font-200 ${classes.subtitlesContainer}`}>
							{data?.subtitles?.length > 0
								? data?.subtitles.map((el) => <span key={el?.title}>{el?.title}</span>)
								: "-"}
						</div>
					</div>
				</div>
			</div>
		);
	};

	return asset && renderInfoData(asset);
};

export default InfoData;
