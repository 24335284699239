import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";

import { voucherPurchase } from "../../../scripts/dataHandlers";
import { useForm } from "../../../hooks/form-hook";
import { VALIDATOR_REQUIRE } from "../../Common/Validation/Validator";
import { Input } from "../../Common/Input/Input";

import settings from "../../../configs/config_settings.json";
import classes from "./VoucherView.module.css";
import Button from "../../Common/Button/Button";
import Colors from "../../Colors";

const customStyles = {
	content: {
		// inset: 0,
		border: "none",
		padding: "50px 20px",
		transition: "all 300ms ease-in-out",
		top: "50%",
		transform: "translate(-50%, -50%)",
		left: "50%",
		background: Colors.background,
		height: "400px",
	},
};

const VoucherView = (props) => {
	const { hideVoucher, moveToReceipt, selectedPackage, itemId } = props;
	const [apiError, setApiError] = useState(null);
	const { t } = useTranslation();

	const { organizationId } = settings.organization;
	const { language } = settings;

	const [cookies, setCookie] = useCookies("");

	const [state, InputHandler] = useForm(
		{
			VOUCHERCODE: {
				value: "",
				isValid: false,
			},
		},
		false
		// the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
	);

	const voucherButtonAction = async () => {
		setApiError(null);

		let TVODASSETID =
			selectedPackage?.category?.pricing === 1 &&
			selectedPackage?.category?.type === 0 &&
			selectedPackage?.category?.contentType === 0
				? itemId
				: undefined;

		const voucherPurchaseResponse = await voucherPurchase({
			userToken: cookies?.user?.userToken,
			languageId: language,
			organizationId,
			productId: selectedPackage?.id,
			voucherCode: state.inputs.VOUCHERCODE.value,
			quantity: 0,
			assetId: TVODASSETID,
		});

		if (voucherPurchaseResponse.data.status === "ok") {
			setApiError(null);

			const myTime = new Date();
			//lets make it use my.icareus.com 10 minutes after the purchase
			const expireTime = new Date(myTime.getTime() + 10 * 60 * 1000).getTime();
			setCookie("useMy", expireTime, { path: "/", maxAge: 600 });

			moveToReceipt();
		} else {
			setApiError(voucherPurchaseResponse?.data?.message);
		}
	};

	return (
		<>
			<ReactModal
				isOpen={true}
				overlayClassName="Overlay"
				style={customStyles}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				preventScroll={true}
				ariaHideApp={false}
				className="search-modal"
			>
				<div className={classes.voucherTitle}>{t("packages.VoucherTitle")}</div>
				<div className={classes.voucherInput}>
					<Input
						id="VOUCHERCODE"
						label="VOUCHERCODE"
						placeholder={t("packages.GiveVoucherCode")}
						type="text"
						element="input"
						validators={[VALIDATOR_REQUIRE()]}
						errorText={t("packages.VoucherCheckCode")}
						onInput={InputHandler}
						iconName={<FontAwesomeIcon icon={faTicketAlt} />}
					/>
				</div>
				{apiError !== null && <div className={classes.voucherApiError}>{apiError}</div>}
				<div className={classes.voucherButtons}>
					<Button onClick={voucherButtonAction} inverse disabled={!state.isValid}>
						{t("packages.Ok")}
					</Button>
					<Button onClick={hideVoucher}>{t("packages.Cancel")}</Button>
				</div>
			</ReactModal>
		</>
	);
};

export default VoucherView;
